<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">按机构统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">

            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
             <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                >
                  <el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                  ></el-option>
                </el-select>
            </div>
            <div title="开班结束时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">开班结束时间:</span>
              <el-date-picker
                v-model="theClassTime"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="260"
                fixed
              />
              
              <el-table-column
                label="开班数量"
                align="left"
                prop="projectNum"
                show-overflow-tooltip
                min-width="100"
              />
            
              <el-table-column
                label="培训人数"
                align="left"
                prop="peopleNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="结业人数"
                align="left"
                prop="graduationNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="合同收款"
                align="left"
                prop="contractAmount"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="到账金额"
                align="left"
                prop="arrivalAmount"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="结算方式"
                align="left"
                prop="settleStyle"
                show-overflow-tooltip
                min-width="100"
              >
               <template slot-scope="scope">
                  {{
                    $setDictionary("AGENCY_SETTLE_STYLE", scope.row.settleStyle)
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="应付服务标准"
                align="left"
                prop="settleStandard"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="应付代理费"
                align="left"
                prop="payableAgencyFee"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="结算班级数量"
                align="left"
                prop="settleProjectNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="到账班级数量"
                align="left"
                prop="accountProjectNum"
                show-overflow-tooltip
                min-width="100"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compId: "",
      theClassTime: "",
      CompanyList:[]
    };
  },
  computed: {},
  created() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        agencyId:this.$route.query.agencyId
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.theClassTime) {
        params.startDate = this.theClassTime[0];
        params.endDate = this.theClassTime[1];
      }
      this.doFetch({
        url: "/agency/ma-agency-organ-static/agencyDetailList",
        params,
        pageNum,
      });
    },
    handleExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
             agencyId:this.$route.query.agencyId
          };
            if (this.compId) {
        params.compId = this.compId;
      }
      if (this.theClassTime) {
        params.startDate = this.theClassTime[0];
        params.endDate = this.theClassTime[1];
      }
          this.$post("/agency/ma-agency-organ-static/exportAgencyDetailList", params).then((res) => {
            if (res.status == "0") {
              window.open(res.message);
            }
          });
        })
        .catch(() => {});
    },
     /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    //列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
